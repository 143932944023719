import React, { Component } from "react";
import classNames from "classnames";
import {
  Section,
  Container
  // Message,
  // MessageHeader,
  // MessageBody,
  // Delete,
  // Button
} from "bloomer";
// import { Link } from "react-router-dom";
// import Cookies from "js-cookie";

import ErrorBag from "../ErrorBag";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import BaseSEO from "../seo/BaseSEO";

import Header from "./Header";
import Footer from "./Footer";

import styles from "./public-site.module.scss";

const SiteAlert = ({ children }) => (
  <div className={styles.siteAlert}>{children}</div>
);

// FUNDRAISING MESSAGE

// const HUNDRED_BIKES_FOR_BETTER_ACCESS_COOKIE_KEY = 'show-hundred-bikes-for-better-access-message'
// class FundraisingMessage extends Component {
//   render() {
//     const show = Cookies.get(HUNDRED_BIKES_FOR_BETTER_ACCESS_COOKIE_KEY) !== 'hidden'

//     return show && (
//       <Section className={styles.fundraisingMessageSection}>
//         <Container className={styles.fundraisingMessage}>
//           <Message
//             isColor="info"
//             // className="is-small"
//           >
//             <MessageHeader>
//               {/* <p>100 Bikes for Better Access</p> */}
//               100 Bikes for Better Access
//               <Delete onClick={() => {
//                 Cookies.set(HUNDRED_BIKES_FOR_BETTER_ACCESS_COOKIE_KEY, 'hidden', { expires: 60 })
//                 this.forceUpdate()
//               }} />
//             </MessageHeader>
//             <MessageBody>
//               <p>
//                 The Bradley Street Bicycle Co-op is partnering with the
//                 Connecticut Community Bike Workshop (CCBW) to provide those most
//                 at-risk in the midst of COVID-19 with safe, affordable, and
//                 reliable transportation to jobs, services, and healthcare. To help
//                 us reach our goal, every dollar we raise will be matched by
//                 Sustainable CT! Check out our fundraising page to learn more about
//                 the project and donate.
//               </p>
//               <p className="has-text-centered">
//                 <Button
//                   isOutlined
//                   isColor="success"
//                   isSize="medium"
//                   href="https://www.patronicity.com/project/100_bikes_for_better_access"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Donate
//                 </Button>
//               </p>
//             </MessageBody>
//           </Message>
//         </Container>
//       </Section>
//     );
//   }
// }

class PublicSite extends Component {
  render() {
    const { hasPaddingTop = true } = this.props;

    return (
      <div className={styles.site}>
        <BaseSEO />
        <ScrollToTopOnMount />
        <SiteAlert>
        {/*  Donate to our Great Give today!{" "}
          <a href="https://www.thegreatgive.org/organizations/bradley-street-bicycle-co-op">
            Donate today{" "}
            <span role="img" aria-labelledby="pointing-right"></span>👉
          </a>*/}
        </SiteAlert>/
        {/* <FundraisingMessage /> */}
        <Header />
        <ErrorBag mb={!hasPaddingTop} />
        <Section
          className={classNames(styles.main, {
            [styles.noPaddingTop]: !hasPaddingTop
          })}
        >
          <Container>{this.props.children}</Container>
        </Section>
        <Footer />
      </div>
    );
  }
}

export default PublicSite;
